
//
// Dropdown
//

@if $enable-dark-mode {
    @include color-mode(dark) {
        .dropdown-menu {
            @include box-shadow($dropdown-box-shadow-dark);
        }
    }
}