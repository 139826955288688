//
// noUiSlider Mixins
//

@mixin nouislider-size($height, $handle-width, $handle-height, $handle-pos-top) {
    height: $height;

    .noUi-handle {
        width: $handle-width;
        height: $handle-height;
        top: -#{$handle-pos-top};
    }
}