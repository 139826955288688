//
// SVG Icon
//


@mixin svg-icon-size($size, $important: false) {
    svg {
        height: $size valueif($important, !important, null);
        width: $size valueif($important, !important, null);
    }
}
