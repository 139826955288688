.header-branner-text {
    background: -webkit-linear-gradient(#E10019, #3B0101);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    margin: 0 !important;
    font-size: 7rem
}

.sub-header-branner-text {
    background: -webkit-linear-gradient(#E10019, #3B0101);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    margin: 0 !important;
    font-size: 4.0rem
}

.content-model-2-text-color {
    color: #F1CF73;
    text-shadow: 0px -1px 1px #A27700F7
}

.content-model-2-sub-text-color {
    color: #848484;
    text-shadow: 0px 3px 7px #646464F7;
    font-size: 2.0rem
}

.line-id {
    font: inherit;
    color: #F1CF73;
    border: 4px solid #F1CF73;
    border-radius: 37px;
    padding: 0.5rem 2rem;
    font-size: 2.0rem;
    width: fit-content;
}

.bg-header-2 h1 {
    font-size: 4.5rem;
}

.bg-header-2 h2 {
    font-size: 2.0rem;
}

.bg-header-2 h3 {
    font-size: 1.5rem;
}

.bg-header-3 h1 {
    font-size: 4.5rem;
}

.bg-header-3 h2 {
    font-size: 2.5rem;
}

.wire-frame-theme h1 {
    font-size: 3.0rem;
}

.wire-frame-theme h2 {
    font-size: 1.5rem;
}

.wire-frame-theme h3 {
    font-size: 1.5rem;
}

.wire-frame-theme h4 {
    font-size: 1.2rem;
}



.index-promotion-text h1 {
    font-size: 24px;
    margin: 0;
}

.index-promotion-text h2 {
    font-size: 14px;
    margin: 0;

}



@media (max-width: 821px) {

    .index-promotion-text h1 {
        font-size: 14px;
        font-weight: 300;
    }

    .index-promotion-text h2 {
        font-size: 7px;
        font-weight: 300;
    }

    .header-branner-text {
        font-size: 3.5rem
    }

    .sub-header-branner-text {
        font-size: 2.0rem
    }

    .line-id {
        font-size: 1.3rem;
    }

    .bg-header-2 h1 {
        font-size: 3rem;
        text-align: center;
    }

    .bg-header-2 h2 {
        text-align: center;
        font-size: 2.0rem;
    }

    .bg-header-2 h3 {
        text-align: center;
        font-size: 1.0rem;
    }

    .bg-header-3 h1 {
        font-size: 3.0rem;
    }

    .bg-header-3 h2 {
        font-size: 2rem;
    }

    .wire-frame-theme {
        padding: 2rem 1rem !important;
    }

    .wire-frame-theme h1 {
        font-size: 2.0rem;
    }

    .wire-frame-theme h2 {
        font-size: 1.0rem;
    }

    button[type=submit] {

        font-size: 1.5rem;

    }
}


@media (max-width: 576px) {
    .header-branner-text {
        font-size: 2.8rem
    }

    .wire-frame-theme {
        margin: 0rem 0px;
        padding: 2rem 0rem !important;
        color: black;
        border: none;
        border-radius: none;
        box-shadow: none;
        align-items: center;
        background: none
    }
}