//
// Browsers
//

@mixin for-edge {
    // Microsoft Edge
	@supports (-ms-ime-align:auto) {
		@content;
	}
}

@mixin for-safari {
	.safari {
		@content;
	}
}

@mixin for-firefox {
	// Firefox
    @-moz-document url-prefix() {
		@content;
	}
}