/* body {
    font-family: 'Kanit', sans-serif !important;
    font-weight: 300
} */

.nav-bar,
.nav-link {
    color: white !important;
}

:root {
    --bs-body-font-family: 'Kanit', sans-serif;
    --bs-body-font-weight: 300;
    --color-main: #fb3434;
    --padding-top: 60px;
    --padding-md-top: 67px;
    --nav-hover-color: 1px solid white;
    --btn-expland-color: #fb3434;
    --blue-text-shadow-379AFC: 3px 2px 6px #fb3434 !important;
    --blue-btn-bg: transparent linear-gradient(0deg, #650000 0%, var(--color-main) 100%) 0% 0% no-repeat padding-box;
    --blue-btn-border: 1px solid #ff6565;
    --white-text-color: #fff !important;
    --black-text-color: #000;
    --theme-text-color: #ff6565;
    --button-text-xlg: normal normal normal 18px/35px Kanit;
    --nav-text-xlg: normal normal normal 24px/35px Kanit;
    --dark-btn-bg: transparent linear-gradient(180deg, #122033 0%, #080E16 77%, #000000 100%) 0% 0% no-repeat padding-box;
    --blue-box-shadow: inset 0px 3px 6px #00000029, 0px 0px 10px #FF7D31;
    --nav-text-lg: normal normal normal 20px/35px Kanit;
    --button-text-sm: normal normal normal 15px/30px Kanit;
    --button-width-lg: 115px;
    --button-width-sm: 100px;
    --button-height-lg: 35px;
    --button-height-sm: 30px;
    --blue-text-shadow-4D9EF8: 3px 2px 6px #FF7D31 !important;
    --blue-text-shadow-299FF5: 3px 3px 6px #FF7D31 !important;
    --border-main: 3px solid #fb3434;
    --border-thick: 5px solid #ff6565;
    --border-thin: 1px solid #FF50008;
    --play-bg-color: #FF7D31;
    --light-bg-gradient: transparent linear-gradient(180deg, #21d90c 0%, #076005 100%) 0% 0% no-repeat padding-box;
    --table-text-color: #fb3434;
    --deposite-btn-color: #fb3434;
    --withdraw-btn-color: #fb3434;
}

.btn-expland {
    --bs-btn-color: rgb(0, 0, 0) !important;
    --bs-btn-bg: #ffd900 !important;
    --bs-btn-border-color: #ffd900;
    --bs-btn-hover-color: #fff;
    --bs-btn-hover-bg: rgb(246, 180, 0);
    --bs-btn-hover-border-color: rgb(246, 180, 0);
    --bs-btn-focus-shadow-rgb: 49, 132, 253;
    --bs-btn-active-color: #fff;
    --bs-btn-active-bg: rgb(246, 180, 0);
    --bs-btn-active-border-color: rgb(246, 180, 0);
    --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
    --bs-btn-disabled-color: #fff;
    --bs-btn-disabled-bg: #ffd900;
    --bs-btn-disabled-border-color: #ffd900;
}

.page-links:hover {
    border-bottom: var(--nav-hover-color)
}

.nav-bar {
    z-index: 999;
    position: fixed;
    top: 0;
    width: 100vw !important;
    height: var(--padding-md-top);
    /* background: transparent linear-gradient(0deg, #0a0d11 0%, #2A3948 100%) 0% 0% no-repeat padding-box; */
    background-color: #0A0A0A;
}

.navbar-expand-xl {
    background: transparent linear-gradient(0deg, #0a0d11 0%, #2A3948 100%) 0% 0% no-repeat padding-box;
    /* background-color: rgb(255, 255, 255, 0.5); */
}

.nav-bar .title-th {
    font-size: 1rem;
}

.nav-bar .title-en {
    font-size: 0.8rem;
}

button[type=submit] {
    margin-top: 10px;
    width: 100%;
    height: 64px;
    font-size: 2.0rem;
    border-radius: 60px;
    color: var(--white-text-color);
    background: transparent linear-gradient(0deg, #650000 0%, var(--color-main) 100%) 0% 0% no-repeat padding-box;
    box-shadow: 0px 0px 20px #ffb936;
    border: 3px solid #b91010;
}

.btn-login {
    background: transparent linear-gradient(0deg, var(--color-main) 0%, var(--blue-btn-border) 100%) 0% 0% no-repeat padding-box;
    border-radius: 30px;
    border: 2px solid #707070;
    text-shadow: 0px 2px 3px #00000087;
    color: #000000;
    width: fit-content;
    padding: 0.1rem 1.5rem;
}

.btn-login:hover {
    cursor: pointer;
    transform: scale(1.05)
}

.btn-register {
    background: transparent linear-gradient(180deg, #2B3A49 0%, #020303 100%) 0% 0% no-repeat padding-box;
    border: 6px solid #707070;
    border-radius: 15px;
    color: var(--white-text-color);
    width: fit-content;
    padding: 0.1rem 1.5rem;
    transition: 0.2s;
}

.btn-register:hover {
    transform: scale(1.2);
}

.bg-header {
    padding-top: var(--padding-md-top);
    /* background-image: url("../static/bg-head.png"); */
    min-height: 10vh;
    width: 100vw;
    background-repeat: no-repeat;
    background-position: center center;
    background-size: cover;
    background-color: #0F0F0F;
}

/* .bg-header-2 {
    background-image: url("../static/bg-head-2.png");
    min-height: 10vh;
    width: 100vw;
    background-repeat: no-repeat;
    background-position: center center;
    background-size: cover;
} */

.bg-header-2-icon .border-right {
    border-right: #707070 1px solid;
}

.bg-header-2-icon {
    background: transparent linear-gradient(180deg, #303030 0%, #000000 43%, #000000 100%) 0% 0% no-repeat padding-box;
    border: 1px solid #707070;
    border-radius: 10px;
}

.bg-header-2 h1,
.bg-header-2 h2,
.bg-header-2 h3 {
    text-align: start;
    color: var(--white-text-color);
    text-shadow: none
}

@media (max-width: 1200px) {
    .bg-header-2-icon .border-right {
        border-right: none
    }
}

@media (max-width: 821px) {
    .branner-icon {
        width: 80px;
    }
    .games-text {
        width: 100px;
    }
}

/* .bg-header-3 {
    margin-bottom: 10px;
    background-image: url("../static/bg-head-3.png");
    min-height: 10vh;
    width: 100vw;
    background-repeat: no-repeat;
    background-position: center center;
    background-size: cover;
    color: var(--color-main);
} */

.bg-header-3 h1,
.bg-header-3 h2,
.bg-header-3 h3 {
    text-align: center;
}

.bg-header-3 .text-btn {
    color: var(--black-text-color) !important;
    background-color: var(--color-main);
}

.branner-detail {
    display: flex;
    justify-content: center;
    align-items: center;
}

.branner-detail-bor-right {
    border-right: #675c00 solid 1px;
}

.branner-detail-content {
    width: fit-content;
}

.branner-detail-content-1 {
    flex-wrap: nowrap;
    font-size: 1rem;
    color: #8F7A41
}

.branner-detail-content-2 {
    flex-wrap: nowrap;
    font-size: 0.7rem;
    color: #848484
}

.under-branner-item {
    background: transparent linear-gradient(304deg, #BFA985 0%, #BFA985 1%, #DCCB99 18%, #F8EDAC 49%, #D2BE86 72%, #AC8F5F 100%, #AC8F5F 100%) 0% 0% no-repeat padding-box;
    border-radius: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 1.8rem;
    color: #000000;
}

.img-event {
    width: 200px;
    transition: 0.2s;
}

.img-event:hover {
    cursor: pointer;
    transform: scale(1.1)
}

.base-footer {
    background: rgb(0, 0, 0);
    color: #707070;
    border: 1px solid #8F7A4145;
}

.border-radius-10px {
    border-radius: 10px;
}

.index-promotion {
    background: transparent linear-gradient(180deg, #180000 0%, #610000 100%) 0% 0% no-repeat padding-box;
    color: var(--white-text-color);
}

.w-50 {
    width: 500px;
}

.content-model-2 {
    background: transparent linear-gradient(180deg, #231F00 0%, #000000 100%) 0% 0% no-repeat padding-box;
    border-radius: 226px;
}

.main-section {
    padding-top: var(--padding-top);
    width: 100%;
    height: auto;
    background-color: #122033;
}

.wire-frame-theme-w-900 {
    max-width: 900px !important;
}

.wire-frame-theme {
    margin: 3rem 0px;
    padding: 2rem;
    border: var(--border-main);
    border-radius: 40px;
    box-shadow: var(--blue-box-shadow);
    align-items: center;
    background: transparent linear-gradient(0deg, #2E2E2E 0%, #101D32 74%, #0F0F10 100%) 0% 0% no-repeat padding-box;
}

.bubble-wire-frame {
    width: 15rem;
    border: var(--border-main);
    border-radius: 100px;
    align-items: center;
    text-align: center;
    color: var(--white-text-color) !important;
}

.wire-frame-theme h1,
.wire-frame-theme h2,
.wire-frame-theme h3,
.wire-frame-theme h4 {
    text-align: center;
    color: var(--white-text-color) !important;
    text-shadow: var(--blue-text-shadow-379AFC);
}

form>div {
    margin: 1rem 0rem
}

.long-field {
    width: 100%;
    height: 45px;
    border: 1px solid #707070;
    background-color: #fff;
    border-radius: 3px;
    display: flex;
}

.long-field>input {
    flex-grow: 1;
    padding-left: 15px;
}

.short-field {
    width: 100%;
    height: 45px;
    display: flex;
    justify-content: space-between;
}

.short-field>* {
    width: 46%;
    min-width: 145px;
    padding-left: 15px;
    border: 1px solid #707070;
    background-color: #fff;
    border-radius: 3px;
}

.promotion-card {
    padding: 1rem;
    min-height: 80px;
    display: flex;
    justify-content: end;
    align-items: flex-end;
}

.promotion-card .explan-btn {
    width: fit-content;
}

.shake:hover {
    /* Start the shake animation and make the animation last for 0.5 seconds */
    animation: shake 0.5s;
    /* When the animation is finished, start again */
    animation-iteration-count: infinite;
}

@keyframes shake {
    0% {
        transform: translate(1px, 1px) rotate(0deg);
    }
    10% {
        transform: translate(-1px, -2px) rotate(-1deg);
    }
    20% {
        transform: translate(-3px, 0px) rotate(1deg);
    }
    30% {
        transform: translate(3px, 2px) rotate(0deg);
    }
    40% {
        transform: translate(1px, -1px) rotate(1deg);
    }
    50% {
        transform: translate(-1px, 2px) rotate(-1deg);
    }
    60% {
        transform: translate(-3px, 1px) rotate(0deg);
    }
    70% {
        transform: translate(3px, 1px) rotate(-1deg);
    }
    80% {
        transform: translate(-1px, -1px) rotate(1deg);
    }
    90% {
        transform: translate(1px, 2px) rotate(0deg);
    }
    100% {
        transform: translate(1px, -2px) rotate(-1deg);
    }
}


/* =================================Template 11 =========================== */

.index-btn {
    color: #fff
}

.index-btn .index-btn-75 {
    width: 75%!important;
}

.index-btn .btn-cus {
    border-radius: 0.5rem;
    background: #222222;
    font-size: 20px;
}

.index-btn .btn-cus:hover {
    cursor: pointer;
    scale: 1.03;
    transition: 0.3s;
}

@media (max-width: 420px) {
    .index-btn .index-btn-75 {
        width: 100%;
    }
}

.text-hader {
    font-size: 24px;
    font-weight: 500;
}

@media (max-width: 420px) {
    .text-hader {
        font-weight: 700;
    }
}

.bg-easy-play {
    background: transparent linear-gradient(180deg, #010000 0%, #3A0000 100%) 0% 0% no-repeat padding-box;
}

.bg-easy-play .bg-easy-play-icon {
    border-radius: 10px;
    border: 1px solid #F89504;
}

.bg-easy-play .icon-img {
    width: 40px;
}

.bg-easy-play .bg-easy-play-list-text {
    font-size: 24px;
}

@media (max-width: 520px) {
    .bg-easy-play .bg-easy-play-list-text {
        font-size: 16px;
    }
}

.bg-promotion {
    background: #220000 0% 0% no-repeat padding-box;
}

.bg-auto-system {
    background: transparent linear-gradient(180deg, #6D020C 0%, #2C0001 63%, #2C0001 100%) 0% 0% no-repeat padding-box;
}

.bg-new-experience-list {
    background-color: #0D0D0D;
    border-radius: 1rem;
}

#new-exp {
    width: 75%;
}

.new-exp-ele .img {
    width: 80px;
}

.new-exp-ele .title {
    font-size: 16px;
    font-weight: 500;
}

.new-exp-ele .desc {
    font-size: 16px;
}

@media (max-width: 821px) {
    #new-exp {
        width: 100%;
    }
    .new-exp-ele .img {
        width: 40px;
    }
    .new-exp-ele .title {
        font-size: 10px;
        font-weight: 500;
    }
    .new-exp-ele .desc {
        font-size: 8px;
    }
}


/* =========Common ==========*/

.col-20 {
    flex: 0 0 auto;
    width: 20%!important;
}

@media (max-width: 407px) {
    .index-btn .btn-cus {
        font-size: 15px;
    }
}

.ft14 {
    font-size: 14px;
}

.ft12 {
    font-size: 12px;
}