//
// Main
//

// Body
body {
  // background-color: var(--#{$prefix}page-bg);
  // background: linear-gradient(135deg, #d00000, #000000, #000, #d00000);
  // background: linear-gradient(135deg, #0541e5, #000000, #000000, #045ce1);
  --bs-bg-opacity: 1;
  background-color: rgba(var(0, 0, 0), var(--bs-bg-opacity)) !important;
  background-size: 100% 100%;
}

// Font color from Content background color
.text-page-bg {
  color: $page-bg;
}

.app-blank {
  background-color: var(--#{$prefix}app-blank-bg) !important;
}

// Desktop Mode
@include media-breakpoint-up(lg) {
  // Fixed header mode
  body.header-fixed {
    padding-top: get($header-config, height, desktop);
  }

  // Wrapper
  .wrapper {
    transition: padding-left get($aside-config, transition-speed) ease,
      margin-right get($aside-config, transition-speed) ease;

    // Aside default enabled and aside fixed modes
    .aside-enabled[data-kt-sticky-aside-sticky="on"] & {
      transition: padding-left get($aside-config, transition-speed) ease;
      padding-left: get($aside-config, width);
    }
  }
}

// Tablet & mobile modes
@include media-breakpoint-down(lg) {
  // Fixed header mode
  body.header-tablet-and-mobile-fixed {
    padding-top: get($header-config, height, tablet-and-mobile);
  }
}
