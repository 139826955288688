/* You can add global styles to this file, and also import other style files */
@import "./assets/css/style.css";
@import "./assets/css/common_style.css";
@import "./assets/css/font_style.css";

@import "./assets/sass/style.scss";
// Replace above style with this css file to enable RTL styles
// @import "./assets/css/style.rtl";
@import "./assets/sass/plugins.scss";
// @import "./assets/css/style.rtl.css";
@import "./assets/sass/style.angular.scss";
// @import "~@angular/material/prebuilt-themes/indigo-pink.css";
*:not(i) {
  font-family: Prompt, sans-serif !important;
}
@import "main.scss";
