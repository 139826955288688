//
// Shadow
//

.shadow-xs {
    box-shadow: var(--#{$prefix}box-shadow-xs);
}

.shadow-sm {
    box-shadow: var(--#{$prefix}box-shadow-sm);
}

.shadow {
    box-shadow: var(--#{$prefix}box-shadow);
}

.shadow-lg {
    box-shadow: var(--#{$prefix}box-shadow-lg);
}