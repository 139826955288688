//
// symbol
//

// Base
.symbol {
    display: inline-block;;
    flex-shrink: 0;
    position: relative;
    @include border-radius($border-radius);

    // Label
    .symbol-label {
        display: flex;
        align-items: center;
        justify-content: center;
        font-weight: 500;
        color: var(--#{$prefix}symbol-label-color);
        background-color: var(--#{$prefix}symbol-label-bg);
        background-repeat: no-repeat;
        background-position: center center;
        background-size: cover;
        @include border-radius($border-radius);

        &:after {
            @include border-radius($border-radius);
        }
    }

    // Badge
    .symbol-badge {
        position: absolute;
        border-radius: 100%;
        top: 0;
        left: 50%;
        transform: translateX(-50%) translateY(-50%) !important;
    }

    // Image
    > img {
        width: 100%;
        flex-shrink: 0;
        display: inline-block;
        @include border-radius($border-radius);
    }

    // Square
    &.symbol-square {
        &,
        > img,
        .symbol-label {
            border-radius: 0 !important;
        }
    }

    // Circle
    &.symbol-circle {
        &,
        > img,
        .symbol-label {
            border-radius: 50%;

            &:after {
                border-radius: 50%;
            }
        }
    }

    // Sizes
    @include symbol-size(get($symbol-sizes, default));

    @each $breakpoint in map-keys($grid-breakpoints) {
        @include media-breakpoint-up($breakpoint) {
            $infix: breakpoint-infix($breakpoint, $grid-breakpoints);

            @each $name, $value in $symbol-sizes {
                @if ($name != 'default') {
                    &.symbol#{$infix}-#{$name} {
                        @include symbol-size($value);
                    }
                }
            }
        }
    }
}

// Group
.symbol-group {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    margin-left: 10px;

    .symbol {
        position: relative;
        z-index: 0;
        margin-left: -10px;
        transition: all 0.3s ease;

        &:hover {
            transition: all 0.3s ease;
            z-index: 1;
        }
    }

    .symbol-badge {
        border: 2px solid var(--#{$prefix}body-bg);
    }

    .symbol-label {
        position: relative;

        &:after {
            display: block;
            content: " ";
            border-radius: inherit;
            position: absolute;
            top: 0;
            right: 0;
            left: 0;
            bottom: 0;
            border: 2px solid var(--#{$prefix}symbol-border-color);
            -webkit-background-clip: padding-box; /* for Safari */
            background-clip: padding-box; /* for IE9+, Firefox 4+, Opera, Chrome */    
        }       
    }

    &.symbol-hover {
        .symbol {
            cursor: pointer;
        }
    }
}
